a {
  text-decoration: none;
  color: unset;
}
.react-confirm-alert-blur {
  height: 100vh;
}
.react-confirm-alert-overlay {
  z-index: 1301;
}
